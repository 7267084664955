/* eslint-disable react-hooks/exhaustive-deps */
import { createElement, lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { Sortable, MultiDrag } from 'sortablejs';
import "antd/dist/antd.min.css";

import Login from 'login/Login-2';
import Layout from './layout/Layout';


Sortable.mount(new MultiDrag());


const breakPoints = size => ({
  desktopWide: size < 1460,
  desktop: size < 1260,
  tabletWide: size < 1060,
  tablet: size < 790,
  tabletSmall: size < 640,
  phablet: size < 560,
  phoneWide: size < 480,
  phone: size < 400
})


const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()


  useEffect(() => {
    const handleBreakPoint = () => {
      const breakPoint = breakPoints(window.innerWidth)
      dispatch({ type: 'BREAK_POINT', breakPoint })
    }
    window.addEventListener('resize', handleBreakPoint)
    return () => window.removeEventListener('resize', handleBreakPoint)
  }, [])


  return (
    <Routes>
      <Route path='/' element={<Navigate to='/dashboard' state={{ from: location.pathname }} replace={true} />} />
      <Route path='/login' element={<Login />} />
      <Route element={<Layout />}>
        {routes.map((route, i) =>
          <Route key={i} path={route.path} element={<Suspense fallback="">{createElement(route.element)}</Suspense>} />)}
      </Route>
    </Routes>)
}

const routes = [
  // dashboard
  {
    path: '/dashboard',
    element: lazy(() => import('./dashboard/Dashboard'))
  },
  // procurement
  {
    path: '/manufacturersNew',
    element: lazy(() => import('./procurement/Brand'))
  },
  {
    path: '/suppliers',
    element: lazy(() => import('./procurement/Agent'))
  },
  {
    path: '/seasons',
    element: lazy(() => import('./procurement/Season'))
  },
  {
    path: '/sizes',
    element: lazy(() => import('./procurement/Size'))
  },
  {
    path: '/product-sku',
    element: lazy(() => import('./procurement/product-sku/ProductSku'))
  },
  {
    path: '/note',
    element: lazy(() => import('./procurement/note/Note'))
  },
  {
    path: '/po',
    element: lazy(() => import('./procurement/po/PO'))
  },
  // product
  {
    path: '/productsNew',
    element: lazy(() => import('./product/product-management/ProductList'))
  },
  {
    path: '/categories',
    // element: lazy(() => import('./product/category-management'))
    element: lazy(() => import('./product/category-management/CategoryMgmt'))
  },
  {
    path: '/stocks',
    element: lazy(() => import('./product/stocks/StockList'))
  },
  // customers
  {
    path: '/customers',
    element: lazy(() => import('./customers/member-list/MemberList'))
  },
  {
    path: '/customers/:id',
    element: lazy(() => import('./customers/member-list/view/MemberView'))
  },
  {
    path: '/customers/:id/edit',
    element: lazy(() => import('./customers/member-list/edit/MemberEdit'))
  },
  {
    path: '/addresses',
    element: lazy(() => import('./customers/addresses/AddressList'))
  },
  {
    path: '/addresses/:id',
    element: lazy(() => import('./customers/addresses/edit/AddressEdit'))
  },
  {
    path: '/orderAddresses/:id',
    element: lazy(() => import('./customers/addresses/edit/OrderAddressEdit'))
  },
  {
    path: '/groups',
    element: lazy(() => import('./customers/groups/GroupList'))
  },
  // {
  //   path: '/carts',
  //   element: lazy(() => import('./customers/shopping-carts/CartList'))
  // },
  {
    path: '/carts/:id',
    element: lazy(() => import('./customers/shopping-carts/CartEdit'))
  },
  // catalog
  {
    path: '/manufacturers',
    element: lazy(() => import('./catalog/brands/BrandList'))
  },
  {
    path: '/manufacturers/:id',
    element: lazy(() => import('./catalog/brands/view/View'))
  },
  {
    path: '/manufacturers/:id/edit',
    element: lazy(() => import('./catalog/brands/edit/BrandEdit'))
  },
  {
    path: '/promocodes',
    element: lazy(() => import('./catalog/promocodes/PromoCodeList'))
  },
  {
    path: '/promocodes/add',
    element: lazy(() => import('./catalog/promocodes/edit/PromoCodeAdd'))
  },
  {
    path: '/promocodes/:id',
    element: lazy(() => import('./catalog/promocodes/view/View'))
  },
  {
    path: '/promocodes/:id/edit',
    element: lazy(() => import('./catalog/promocodes/edit/PromoCodeEdit'))
  },
  {
    path: '/products/:id/edit',
    element: lazy(() => import('./catalog/products/edit'))
  },
  // status tag
  {
    path: '/products/statustags',
    element: lazy(() => import('./catalog/products/statustags/StatusTagsList'))
  },
  {
    path: '/products/statustags/add',
    element: lazy(() => import('./catalog/products/statustags/edit/StatusTagAdd'))
  },
  {
    path: '/products/statustags/:id/edit',
    element: lazy(() => import('./catalog/products/statustags/edit/StatusTagEdit'))
  },
  // orders
  {
    path: '/orders',
    element: lazy(() => import('./orders/orders(new)/OrderList'))
  },
  {
    path: '/orders/:id',
    element: lazy(() => import('./orders/orders(new)/Edit'))
  },
  {
    path: '/orders/monthlyBilling',
    element: lazy(() => import('./orders/monthlyBilling'))
  },
  {
    path: '/order-return',
    element: lazy(() => import('./orders/merchandise-returns/OrderReturns'))
  },
  {
    path: '/order-return/:id',
    element: lazy(() => import('./orders/merchandise-returns/edit/OrderReturnEdit'))
  },
  {
    path: '/push-notifications',
    element: lazy(() => import('./pushNotifications/NotificationList'))
  },
  {
    path: '/push-notifications/:id',
    element: lazy(() => import('./pushNotifications/EditNotification'))
  },
  {
    path: '/push-notifications/add',
    element: lazy(() => import('./pushNotifications/AddNotification'))
  },
  // accountant
  {
    path: '/order-report',
    element: lazy(() => import('./accountant/OrderReport'))
  },
  // product-feed-tags
  {
    path: '/product-feed-tags',
    element: lazy(() => import('./product/product-feed-tags/ProductFeedTagsList'))
  },
  {
    path: '/product-feed-tags/:id',
    element: lazy(() => import('./product/product-feed-tags/EditProductFeedTag'))
  },
  {
    path: '/product-feed-tags/add',
    element: lazy(() => import('./product/product-feed-tags/AddProductFeedTag'))
  },
  // price-rules
  {
    path: '/cashback',
    element: lazy(() => import('./price-rules/cashback/CashbackList'))
  },
  // administration
  {
    path: '/preference',
    element: lazy(() => import('./administration/preference/Preference'))
  },
  {
    path: '/employees',
    element: lazy(() => import('./administration/employees/Employees'))
  },
  {
    path: '/manufacturers/:id_manufacturer/banners',
    element: lazy(() => import('./procurement/Banner/BrandBannerList'))
  },
  {
    path: '/manufacturers/:id_manufacturer/banners/add',
    element: lazy(() => import('./procurement/Banner/BrandBannerAdd'))
  },
  {
    path: '/manufacturers/:id_manufacturer/banners/:id',
    element: lazy(() => import('./procurement/Banner/BrandBannerEdit'))
  },
  {
    path: '/categories/:id_category/banners',
    element: lazy(() => import('./product/category-management/CategoryBanner/CategoryBannerList'))
  },
  {
    path: '/categories/:id_category/banners/add',
    element: lazy(() => import('./product/category-management/CategoryBanner/CategoryBannerAdd'))
  },
  {
    path: '/categories/:id_category/banners/:id',
    element: lazy(() => import('./product/category-management/CategoryBanner/CategoryBannerEdit'))
  },
  // Marketing
  {
    path: '/shipping-country-rule-map',
    element: lazy(() => import('./marketing/shipping-country-rule-map/ShippingCountryRuleMap'))
  },
  {
    path: '/hk-warehouse',
    element: lazy(() => import('./hk-warehouse/HKWarehouse'))
  },
  {
    path: '/hk-warehouse/:id',
    element: lazy(() => import('./hk-warehouse/edit/HKEdit'))
  },
  {
    path: '/navigation',
    element: lazy(() => import('./navigation/Navigation'))
  },
  {
    path: '/estimate-arriving-po',
    element: lazy(() => import('./warehouse/estimate-arriving-po/ArrivingPO'))
  },
  {
    path: '/estimate-arriving-po/:id',
    element: lazy(() => import('./warehouse/estimate-arriving-po/ArrivingPOEdit'))
  },
  {
    path: '/rewarehouse-entries',
    element: lazy(() => import('./warehouse/rewarehouse-entries/Rewarehouse'))
  },
  {
    path: '/barcode-generator',
    element: lazy(() => import('./warehouse/barcode-generator/BarcodeGenerator'))
  },
  {
    path: '/tool/translate',
    element: lazy(() => import('./tool/Translate'))
  },
  {
    path: '/tool/completion',
    element: lazy(() => import('./tool/Completion'))
  },
  {
    path: '/tool/brand-category-desc',
    element: lazy(() => import('./tool/ManufacturerCategory'))
  },
  {
    path: '/tool/auto-upload',
    element: lazy(() => import('./tool/AutoUpload'))
  },
  {
    path: '/app-config',
    element: lazy(() => import('./app-config/AppConfig'))
  },
  {
    path: '/messaging-service',
    element: lazy(() => import('./marketing/messaging-service/MessagingServiceList'))
  },
  {
    path: '/messaging-service/:id',
    element: lazy(() => import('./marketing/messaging-service/EditMessagingService'))
  },
  {
    path: '/messaging-service/add',
    element: lazy(() => import('./marketing/messaging-service/AddMessagingService'))
  },
]

export default App
